import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'components/Layout';
import Meta from 'components/Meta';

// import { Main } from 'styles/privacyPolicy';
import 'styles/privacyPolicy.scss';

const PrivacyPolicy = ({ location }) => (
  <Layout location={location}>
    <Meta
      title="Privacy Policy | ruttl 📝"
      description="Learn about the data we collect, how we protect it, and your rights as a user of ruttl - visual feedback and collaboration tool."
      url="https://ruttl.com/privacy-policy/"
    />
    <main className="privacy-policy-styled-main">
      <div className="container">
        <div className="inner-common-hero">
          <h1 className="privacy-head">Privacy Policy</h1>
          <p>Effective October 23, 2020 </p>
        </div>
      </div>

      <section>
        <div className="container">
          <div className="privacy-content">
            <h2>Overview:</h2>

            <p>
              This privacy policy governs your use of our website and service
              located at https://web.ruttl.com and https://ruttl.com (from here
              on referred to as ruttl) and owned by Brucira Online Solutions
              Pvt. Ltd. (from here on referred to as Brucira). By using ruttl
              and its subdirectories, you agree and accept this policy in full.
              If you disagree with any part of this policy, do not use ruttl as
              that condition prohibits you from doing so.
            </p>

            <h3>To whom does this policy apply?</h3>
            <p>
              This policy applies to both website/product visitors and customers
              (“you”) of ruttl, and is published by “us” - ruttl and its parent
              company, Brucira. We commit to protect all personal data that we
              receive from our users.
            </p>

            <h3>Acceptance of terms</h3>
            <p>
              By visiting our ​website/product​, you agree to the terms of this
              Privacy Policy.
            </p>
            <h2>What we collect (and how it is used and shared)</h2>

            <ul>
              <li>
                Personal Information - Information that can be used to identify
                you
              </li>
              <li>
                Other Information - Information that does not identify you
              </li>
            </ul>
            <p>
              When you request information about our products and services or
              sign up to receive information from us, you may enter your email
              address and password, in which case your information will be used
              to contact you about our products and services. We will honor any
              requests that you make asking us to no longer contact you and will
              provide you with a convenient means to unsubscribe or opt-out of
              any communication. When you purchase services from us, we will
              collect the Personal Information that you submitted in order to:
            </p>

            <ul>
              <li>administer or improve our services to you, </li>
              <li>administer our rewards and promotional programs,</li>
              <li>improve our website/product and services to you,</li>
              <li>solicit your feedback,</li>
              <li>inform you about our products and services.</li>
            </ul>
            <p>
              You may choose to opt-out of unnecessary communications, but
              please understand that if and while you are a customer, we have
              legitimate reasons to contact you to administer the services that
              we provide to you, respond to issues, and manage or discuss our
              agreement. If and when you otherwise share your Personal
              Information with us by email by submitting an online form on our
              website/product or contact us using other means, we use that
              Personal Information in order to respond to you.
            </p>

            <p>
              We collect Other Information from our website/product visitors
              that is publicly transmitted by devices and web browsers in order
              to understand basic information about the categories and frequency
              of visitors that come to our website/product. This visitor
              information includes IP address, your location, your browser type,
              the fonts you have installed, basic information about your device,
              and other information that is automatically transmitted from all
              browsers. Although we do not use it for the purpose of identifying
              you personally, the combination of Other Information from your
              browser is sufficiently unique that it can be used to identify you
              as a repeat visitor. If you do not wish to share such information
              with us (or anyone else while browsing), you should consult your
              browser’s manual or identify a browser plug-in that will prevent
              the sharing of this information, but it may prevent your browser
              from functioning appropriately on the web.
            </p>

            <p>
              We also collect Other Information using “cookie“ technology and
              JavaScript tags. Cookies are small packets of data that a website
              stores on your computer’s hard drive so that your computer will
              “remember“ information about your visit. We may use both session
              cookies (which expire once you close your web browser) and
              persistent cookies (which stay on your computer until you delete
              them) as well as JavaScript tags to enhance your experience using
              the website. This Other Information is not linked to you
              personally, but allows the delivery of relevant information to you
              elsewhere on the web because of your browser information. We will
              obtain your consent for additional uses of your Personal
              Information for purposes that are not disclosed in this privacy
              policy.
            </p>

            <h2>How we share information:</h2>

            <p>
              We may employ
              <a href="https://cloud.google.com/security/privacy">GCP,</a>
              <a href="https://www.zoho.com/privacy.html">Zoho,</a>
              <a href="https://stripe.com/en-in/privacy">Stripe</a>
              and/or other individual contractors to perform functions on our
              behalf. Examples may include providing technical assistance,
              customer service, and marketing assistance. These other companies
              will have access to the minimum amount of Personal Information
              about you, only as necessary to perform their functions and to the
              extent permitted by law. We share aggregate information that does
              not identify you with our affiliates, agents, and business
              partners and disclose aggregated user statistics in order to
              describe our products and services to current and prospective
              business partners and to other third parties for other lawful
              purposes.
            </p>

            <p>
              In order to provide our services and administer our rewards and
              promotional programs, we share your Personal Information with our
              third-party promotional and marketing partners, including, without
              limitation, businesses participating in our various programs. We
              may share your Information with our parent company, subsidiaries,
              joint ventures, or other companies under common control with us.
              As we develop our businesses structure, we might sell or buy
              businesses or assets. In the event of a corporate sale, merger,
              reorganization, sale of assets, dissolution, or similar event, the
              Personal Information about customers may be part of the
              transferred information. To the extent permitted or required by
              law, we may also disclose the information when required by law,
              court order, national security, law enforcement authority or
              regulatory authority; or whenever we believe that disclosing such
              Information is necessary or advisable to protect the rights,
              property, or safety of us or others.
            </p>

            <p>
              Your information will be processed in Mumbai, India where we are
              based, and it is necessary for your personal data to be processed
              in Mumbai, India in order to provide services or publish this
              website/product. We remain responsible for our sharing of Personal
              Information with third parties in cases of onward transfer.
            </p>

            <h2>Retention of your Personal Information:</h2>
            <p>
              We will keep your Personal Information for as long as we have your
              consent to keep the Personal Information that is reasonably based
              upon the purpose for which it was collected, unless it is retained
              for a legitimate business purpose that does not pose a risk to
              your privacy rights or otherwise required by law as authorized or
              necessary under any applicable agreement with you. At any time if
              you no longer want us to keep any of your Personal Information,
              you may contact us and request us to erase it, access it, correct
              it, or restrict or object to further processing and sharing. If
              you make such a request, we will comply, unless we have a specific
              contractual, regulatory or legal reason to have to retain the
              Personal Information or refuse the request. For customers,
              whenever practicable, we provide you with the ability to
              administer and erase your Personal Information from our services.
            </p>

            <h2>External Websites:</h2>
            <p>
              This website/product may contain links to third-party websites.
              ruttl has no control over the privacy practices or the content of
              these websites. As such, we are not responsible for the content or
              the privacy policies of those third-party websites. Please check
              the applicable third-party privacy policy and terms of use when
              visiting any other website.
            </p>

            <h2>Contacting us regarding our Privacy Policy:</h2>
            <p>
              You have a right to access your Personal Information. We commit to
              resolve complaints about our collection or use of your Personal
              Information. Any inquiries or complaints regarding our Privacy
              Policy should first be contacted at&nbsp;
              <a href="mailto:support@ruttl.com">support@ruttl.com.</a>
            </p>

            <p>
              You may also send a letter to Brucira Online Solutions Pvt. Ltd.,
              B504, PNG Building, Mumbai, Maharashtra 400076, India
            </p>

            <p>
              This policy may be changed at any time at our discretion. If we
              update this policy, we will post the updates to this page on our
              website and update the Effective Date at the top. Your use of this
              website/product after any update indicates your agreement.
            </p>
          </div>
        </div>
      </section>
    </main>
  </Layout>
);

PrivacyPolicy.propTypes = {
  location: PropTypes.object,
};

export default PrivacyPolicy;
